<template>
  <div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import store from "@/store";
  export default {
    name: 'ShareView',

    computed: {
      userinfo: {
        get() {
          return store.state.user.Login;
        }
      }
    },

    data() {
      const user = store.state.user.Login;

      if (user.state) {
        store.dispatch("schedule/ShareUrl", this.$route.params);
      } else {
        store.dispatch("user/openLogin", true);
      }

      return {};
    },
  };

</script>
